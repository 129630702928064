import getItems from "./getItems";
import getItemsSummary from "./getItemsSummary";
import addItem from "./addItem";
import addItems from "./addItems";
import removeItem from "./removeItem";
import removeItems from "./removeItems";
import updateItem from "./updateItem";
import getSharedSummary from "./getSharedSummary";
import getSharedItems from "./getSharedItems";

/**
 * request options
 * @global
 * @typedef {Object} requestOptions
 * @property {Integer} [timeout] request timeout (ms). Default is 1000ms if not specified
 * @property {Object} [additionalLoggingParams] additional logging parameters
 * @property {Function} [cancelTokenExecutor] cancel token executor
 */
export default {
  getItems,
  getItemsSummary,
  addItem,
  addItems,
  removeItem,
  removeItems,
  updateItem,
  getSharedSummary,
  getSharedItems
};
