import isZeroOrPositiveInteger from "../isZeroOrPositiveInteger";

const validateIntegers = values => {
  const errors = Object.entries(values).reduce((allErrors, [key, value]) => {
    if (!isZeroOrPositiveInteger(value)) {
      allErrors.push(TypeError(`${key} should be a non-negative integer`));
    }
    return allErrors;
  }, []);

  if (errors.length) {
    throw new TypeError(errors.join("\n"));
  }
};

export default validateIntegers;
