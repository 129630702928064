import validateShareId from "../validateShareId";
import validateRequestOptions from "../validateRequestOptions";
import { SHARED_LIST_PATH } from "../constants";

/**
 * Get a shared summary
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {String} params.shareId The unique shareId (GUID) for sharing a particular SavedList.
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise<external:API.SharedListProductResponse>} Promise resolving to SharedListProductResponse.
 * @throws {TypeError} shareId value should be a valid guid
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/View the Shared SavedList|API Documentation}
 * @example
 * sdk.getSharedSummary(shareId);
 */
const getSharedSummary = (
  { api, context: queryParams },
  { shareId, requestOptions } = {}
) => {
  validateShareId(shareId);
  validateRequestOptions(requestOptions);
  return api.get({
    isAuthRequired: false,
    path: `${SHARED_LIST_PATH}${shareId}`,
    queryParams,
    requestOptions
  });
};

export default getSharedSummary;
