import isZeroOrPositiveInteger from "../isZeroOrPositiveInteger";

const getInvalidOptionsErrors = requestOptions => {
  const validOptions = [
    "timeout",
    "additionalLoggingParams",
    "cancelTokenExecutor"
  ];
  return Object.keys(requestOptions).map(key => {
    if (!validOptions.includes(key)) {
      return `requestOptions.${key} is invalid`;
    }
  });
};

const getTimeoutError = timeout => {
  if (timeout !== undefined && !isZeroOrPositiveInteger(timeout)) {
    return "requestOptions.timeout should be a non-negative integer";
  }
};

const isPojo = object =>
  object === null || typeof object !== "object"
    ? false
    : Object.getPrototypeOf(object) === Object.prototype;

const getAdditionalLoggingParamsError = additionalLoggingParams => {
  if (
    additionalLoggingParams !== undefined &&
    !isPojo(additionalLoggingParams)
  ) {
    return "requestOptions.additionalLoggingParams should be an object";
  }
};

const getCancelTokenExecutorError = cancelTokenExecutor => {
  if (!["function", "undefined"].includes(typeof cancelTokenExecutor)) {
    return "requestOptions.cancelTokenExecutor should be a function";
  }
};

const validateRequestOptions = (requestOptions = {}) => {
  const { timeout, additionalLoggingParams, cancelTokenExecutor } =
    requestOptions;

  const errors = getInvalidOptionsErrors(requestOptions)
    .concat(getTimeoutError(timeout))
    .concat(getAdditionalLoggingParamsError(additionalLoggingParams))
    .concat(getCancelTokenExecutorError(cancelTokenExecutor))
    .filter(e => e !== undefined);

  if (errors.length) {
    throw new TypeError(errors.join("\n"));
  }
};

export default validateRequestOptions;
