import validateRequestOptions from "../validateRequestOptions";
import validateGetAuthorisationDelegate from "../validateGetAuthorisationDelegate";
import { DEFAULT_ITEMS_SUMMARY_URL_PATH } from "../constants";

/**
 * Get a list of all saved item IDs
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise<external:API.ItemsSummaryResponse>} Promise resolving to ItemsSummaryResponse.
 * @throws {TypeError} configuration.{@link getAuthorisationDelegate|getAuthorisation} is required, since this is an authorised endpoint.
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Get Summary Saved Items|API Documentation}
 * @example
 * sdk.getItemsSummary();
 */
const getItemsSummary = (
  {
    api,
    context: { store, lang, keyStoreDataversion },
    configuration: { getAuthorisation } = {}
  },
  { requestOptions } = {}
) => {
  validateGetAuthorisationDelegate(getAuthorisation);
  validateRequestOptions(requestOptions);
  return api.get({
    path: DEFAULT_ITEMS_SUMMARY_URL_PATH,
    queryParams: {
      lang,
      store,
      keyStoreDataversion
    },
    requestOptions
  });
};

export default getItemsSummary;
