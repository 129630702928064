import {
  asosNamespace,
  customerNamespace,
  savedItemsSdkNamespace
} from "../../globalNamespaces";

export const NAMESPACE = `${asosNamespace}.${customerNamespace}.${savedItemsSdkNamespace}`;
export const EVENT_STARTED_KEY = "started";
const DEFAULT_ITEMS_URL = "me/lists/default";
export const DEFAULT_LIST_ITEMS_URL_PATH = DEFAULT_ITEMS_URL + "/items";
export const DEFAULT_ITEMS_SUMMARY_URL_PATH = DEFAULT_ITEMS_URL + "/summary";
export const SHARED_LIST_PATH = "shared/";

/**
 * the order to sort by when getting saved items
 * @readonly
 * @memberof asos.customer.savedItemsSdk
 * @enum {string}
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Get Saved Items|API Documentation}
 */
const sortOrders = {
  /** sort saved items in descending order */
  SORT_ORDER_DESCENDING: "dsc",
  /** sort saved items in ascending order */
  SORT_ORDER_ASCENDING: "asc"
};

/**
 * what to sort by when getting saved items
 * @readonly
 * @memberof asos.customer.savedItemsSdk
 * @enum {string}
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Get Saved Items|API Documentation}
 */
const sorts = {
  /** sort saved items by date */
  SORTBY_DATE: "date",
  /** sort saved items by discount */
  SORTBY_DISCOUNT: "discount",
  /** sort saved items by price */
  SORTBY_PRICE: "price",
  /** sort saved items by brand */
  SORTBY_BRAND: "brand",
  /** sort saved items by stock level */
  SORTBY_STOCK: "stock",
  /** sort saved items by recently discounted */
  SORTBY_RECENTLY_DISCOUNTED: "recentlydiscounted"
};

/**
 * expand option.
 * N.B. The SDK does not implement "ids" since the {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Get Summary Saved Items} will be used instead, when needed by web.
 * @readonly
 * @memberof asos.customer.savedItemsSdk
 * @enum {string}
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Get Saved Items|API Documentation}
 */
const expands = {
  /** expand variant information */
  EXPAND_VARIANTS: "variants"
};

export { sorts, sortOrders, expands };
