import isZeroOrPositiveInteger from "../../isZeroOrPositiveInteger";
import isPositiveInteger from "../../isPositiveInteger";

const getItemPrefix = item => `item: ${JSON.stringify(item)} - `;

const getProductError = item => {
  const { productId } = item;
  if (!isPositiveInteger(productId)) {
    return `${getItemPrefix(item)}productId is required, as a positive integer`;
  }
};

const getMissingVariantOrColourWayIdError = (variantId, colourWayId) => {
  if (variantId === null && colourWayId === null) {
    return "either variantId or colourWayId is required";
  }
};

const getBothVariantAndColourWayIdError = (variantId, colourWayId) => {
  if (variantId !== null && colourWayId !== null) {
    return "only one of variantId or colourWayId should be supplied";
  }
};

const getColourWayIdError = colourWayId => {
  if (colourWayId !== null && !isZeroOrPositiveInteger(colourWayId)) {
    return "colourWayId should be a non-negative integer";
  }
};

const getVariantIdError = variantId => {
  if (variantId !== null && !isPositiveInteger(variantId)) {
    return "variantId should be a positive integer";
  }
};

const getVariantOrColourWayIdError = item => {
  const { variantId = null, colourWayId = null } = item;
  const itemPrefix = getItemPrefix(item);
  let error = getMissingVariantOrColourWayIdError(variantId, colourWayId);
  if (error) {
    return `${itemPrefix}${error}`;
  }
  error = getBothVariantAndColourWayIdError(variantId, colourWayId);
  if (error) {
    return `${itemPrefix}${error}`;
  }
  error = getColourWayIdError(colourWayId);
  if (error) {
    return `${itemPrefix}${error}`;
  }
  error = getVariantIdError(variantId);
  if (error) {
    return `${itemPrefix}${error}`;
  }
};

const validateItems = items => {
  const errors = !Array.isArray(items)
    ? ["items should be an array"]
    : items.length === 0
    ? ["items are required"]
    : items
        .map(item => getProductError(item))
        .concat(items.map(item => getVariantOrColourWayIdError(item)))
        .filter(e => e !== undefined);

  if (errors.length) {
    throw new TypeError(errors.join("\n"));
  }
};

export default validateItems;
