import isPositiveInteger from "../../isPositiveInteger";
import getIdError from "../getIdError";

const validateParams = ({ itemId, variantId }) => {
  const errors = [
    getIdError(itemId, "itemId"),
    !isPositiveInteger(variantId)
      ? `variantId (${variantId}) should be a positive integer`
      : undefined
  ].filter(e => e !== undefined);

  if (errors.length) {
    throw new TypeError(errors.join(", "));
  }
};

export default validateParams;
