import getIdError from "../getIdError";

const validateItemIds = itemIds => {
  const errors = !Array.isArray(itemIds)
    ? ["itemIds should be an array"]
    : itemIds.length === 0
    ? ["itemIds are required"]
    : itemIds
        .map(itemId => getIdError(itemId, "itemId"))
        .filter(e => e !== undefined);

  if (errors.length) {
    throw new TypeError(errors.join("\n"));
  }

  if (itemIds.join(",").length > 500) {
    throw new RangeError(
      "itemIds limited to 500 characters when comma separated"
    );
  }
};

export default validateItemIds;
