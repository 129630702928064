import validateIntegers from "./validateIntegers";
import getLimitAndOffsetError from "./getLimitAndOffsetError";
import getExpandError from "./getExpandError";

import { sortOrders, sorts, NAMESPACE } from "./constants";

const getSortError = sort => {
  return !Object.values(sorts).includes(sort)
    ? `sort should be one of ${NAMESPACE}.sorts: ${Object.keys(sorts).join(
        ", "
      )}`
    : undefined;
};

const getSortOrderError = sortOrder => {
  return !Object.values(sortOrders).includes(sortOrder)
    ? `sortOrder should be one of ${NAMESPACE}.sortOrders: ${Object.keys(
        sortOrders
      ).join(", ")}`
    : undefined;
};

const validateValues = (offset, limit, sort, sortOrder, expand) => {
  const errors = []
    .concat(getLimitAndOffsetError(limit, offset))
    .concat(getSortError(sort))
    .concat(getSortOrderError(sortOrder))
    .concat(getExpandError(expand))
    .filter(e => e !== undefined);

  if (errors.length) {
    throw new RangeError(errors.join("\n"));
  }
};

const validateGetParams = ({ offset, limit, sort, sortOrder, expand }) => {
  validateIntegers({ offset, limit });
  validateValues(offset, limit, sort, sortOrder, expand);
};

export default validateGetParams;
