import isPositiveInteger from "../../../isPositiveInteger";
import { getRetriesErrorMessage as errorMessage } from "./constants";

const getRetriesError = retries => {
  if (retries !== undefined && !isPositiveInteger(retries)) {
    return errorMessage;
  }
};

export default getRetriesError;
