import getIdError from "./getIdError";

const validateShareId = shareId => {
  const error = getIdError(shareId, "shareId");
  if (error) {
    throw new TypeError(error);
  }
};

export default validateShareId;
