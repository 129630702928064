import { NAMESPACE, expands } from "./constants";

const getExpandError = expand => {
  return expand !== undefined && !Object.values(expands).includes(expand)
    ? `expand should be one of ${NAMESPACE}.expands: ${Object.keys(
        expands
      ).join(", ")}`
    : undefined;
};

export default getExpandError;
