const mapObjectValues = (obj, decorateFn) =>
  Object.entries(obj).reduce(
    (obj, [key, value]) => ({
      ...obj,
      [key]: decorateFn(value, key)
    }),
    {}
  );

export default mapObjectValues;
