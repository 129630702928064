import validateShareId from "../validateShareId";
import validateGetParams from "../validateGetParams";
import validateRequestOptions from "../validateRequestOptions";
import { sortOrders, sorts, SHARED_LIST_PATH } from "../constants";

/**
 * Get a shared list's items
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {Integer} [params.offset=0] offset
 * @param {Integer} [params.limit=30] limit
 * @param {asos.customer.savedItemsSdk.sorts} [params.sort=SORTBY_DATE] sort direction
 * @param {asos.customer.savedItemsSdk.sortOrders} [params.sortOrder=SORT_ORDER_DESCENDING] sort order
 * @param {asos.customer.savedItemsSdk.expands} [params.expand] expand parameter
 * @param {String} params.shareId The unique shareId (GUID) for sharing a particular SavedList.
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise<external:API.SharedListProductResponse>} Promise resolving to SharedListProductResponse.
 * @throws {TypeError} offset should be a non-negative integer
 * @throws {TypeError} limit should be a non-negative integer
 * @throws {RangeError} maximum offset is 9999
 * @throws {RangeError} maximum limit is 200
 * @throws {RangeError} sort must be a member of {@link asos.customer.savedItemsSdk.sorts}, if supplied.
 * @throws {RangeError} sortOrder must be a member of {@link asos.customer.savedItemsSdk.sortOrders}, if supplied.
 * @throws {RangeError} expand should be one of {@link asos.customer.savedItemsSdk.expands}
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/View the SavedList name and associated products|API Documentation}
 * @example
 * sdk.getSharedItems({ shareId: "00000000-0000-0000-0000-000000000001", offset: 100, limit: 50, sort: SORTBY_BRAND, sortOrder: SORT_ORDER_ASCENDING });
 */
const getSharedItems = (
  { api, context },
  {
    shareId,
    offset = 0,
    limit = 30,
    sort = sorts.SORTBY_DATE,
    sortOrder = sortOrders.SORT_ORDER_DESCENDING,
    expand,
    requestOptions
  } = {}
) => {
  validateGetParams({ expand, offset, limit, sort, sortOrder });
  validateShareId(shareId);
  validateRequestOptions(requestOptions);
  return api.get({
    path: `${SHARED_LIST_PATH}${shareId}/items`,
    isAuthRequired: false,
    queryParams: {
      offset,
      limit,
      sort,
      sortOrder,
      ...(expand ? { expand } : {}),
      ...context
    },
    requestOptions
  });
};

export default getSharedItems;
