const offsetMax = 9999;
const limitMax = 200;

const getLimitAndOffsetError = (limit, offset) => {
  const errors = []
    .concat(limit > limitMax ? `maximum limit is ${limitMax}` : undefined)
    .concat(offset > offsetMax ? `maximum offset is ${offsetMax}` : undefined)
    .filter(error => typeof error !== "undefined");

  return errors;
};

export default getLimitAndOffsetError;
