import getIdError from "../getIdError";

const validateItemId = itemId => {
  const error = getIdError(itemId, "itemId");
  if (error) {
    throw new TypeError(error);
  }
};

export default validateItemId;
