import validateItemIds from "./validateItemIds";
import validateRequestOptions from "../validateRequestOptions";
import validateGetAuthorisationDelegate from "../validateGetAuthorisationDelegate";
import { DEFAULT_LIST_ITEMS_URL_PATH } from "../constants";

/**
 * Remove items from saved items
 * @memberof module:sdkInstance
 * @inner
 * @param {Object} params
 * @param {string[]} params.itemIds saved item ids to remove
 * @param {requestOptions} [params.requestOptions] request options
 * @return {Promise<external:API.DeleteResponse>} Promise resolving to DeleteResponse.
 * @throws {TypeError} itemIds should be an array of non-empty strings.
 * @throws {TypeError} configuration.{@link getAuthorisationDelegate|getAuthorisation} is required, since this is an authorised endpoint.
 * @throws {RangeError} itemIds limited to 500 characters when comma separated.
 * @see {@link https://redoc-service.asoscloud.com/files/AWG/master/customer-saveditems-v3.html#operation/Remove Saved Item(s)|API Documentation}
 * @example
 * sdk.removeItems({ itemIds: ["55596cc6-1799-4e6c-b5ff-009928d35ab6","0e56bbf4-563a-4687-8647-8d87094049ad"] });
 */
const removeItems = (
  { api, configuration: { getAuthorisation } = {} },
  { itemIds, requestOptions } = {}
) => {
  validateGetAuthorisationDelegate(getAuthorisation);
  validateItemIds(itemIds);
  validateRequestOptions(requestOptions);
  return api.delete({
    path: DEFAULT_LIST_ITEMS_URL_PATH,
    queryParams: { items: itemIds.join(",") },
    requestOptions
  });
};

export default removeItems;
