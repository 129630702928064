import { getEventBusErrorMessage as errorMessage } from "./constants";

const isObject = candidate =>
  typeof candidate === "object" && candidate !== null;

const isEventBusInstance = eventBus =>
  isObject(eventBus) && typeof eventBus.emit === "function";

const getEventBusError = eventBus => {
  if (eventBus !== undefined && !isEventBusInstance(eventBus)) {
    return errorMessage;
  }
};

export default getEventBusError;
